
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import productDetail from '@/components/productDetail.vue'

@Options({
  components: {
    HelloWorld,
    productDetail
  },
})
export default class Home extends Vue {
  dialogVisible = false

   bgImg = require("@/assets/bg.jpg")

   aboutUs = require("@/assets/about.jpg")

   map =require("@/assets/map.png")

   productAll = require("@/assets/2.jpg")
   productAll2 = require("@/assets/1.png")

   productAll3 = require("@/assets/3.jpg")

   productInfo = {}

   aboutText = `成都卓信奇移科技有限公司成立于2018年07月18日，注册地位于中国（四川）自由贸易试验区成都高新区世纪城南路599号6栋5层505号，法定代表人为李泉材。经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；广告制作；广告设计、代理；广告发布；办公用品销售；机械设备销售；机械电气设备销售；电线、电缆经营；电子产品销售；电工仪器仪表销售；智能仪器仪表销售；金属制品销售；软件销售；计算机软硬件及辅助设备批发；信息系统集成服务；家用电器销售；家用电器零配件销售；礼品花卉销售；办公设备耗材销售；日用口罩（非医用）销售；针纺织品销售；互联网销售（除销售需要许可的商品）；钟表销售；食用农产品零售；企业形象策划；会议及展览服务；国内贸易代理；企业管理咨询；工艺美术品及礼仪用品销售（象牙及其制品除外）。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：第二类增值电信业务。`

   bannerList = [
    require("@/assets/banner/1.jpg"),
    require("@/assets/banner/2.jpg"),
    require("@/assets/banner/3.jpg"),
    require("@/assets/banner/4.jpg"),
    require("@/assets/banner/5.jpg"),
   ]

   productList = [
    {id:1, img:  require("@/assets/product/33.jpg"),},
    {id:2, img: require("@/assets/product/11.jpg"),},
    {id:3, img: require("@/assets/product/2.jpg"),},
    {id:4, img: require("@/assets/product/22.jpg"),},
    {id:5, img:  require("@/assets/product/4.jpg"),},
    {id:6, img:   require("@/assets/product/6.jpg")},
   ]

  show = false

  showImgDialog(item: any){
    console.log(item)

    switch (item.id) {
      case 1:
        this.productInfo = {
          text: '一心诚与极上北京安宫牛黄丸于2023年达成代理合作。极上北京安宫牛黄丸是中国北京传统古方，以水牛角、天然牛黄、天然麝香、珍珠等多种名贵药材制成，保护心脑血管健康。以最珍贵呵护健康。',
          img1: require("@/assets/product/porduct1_5.jpg"),
          img2: require("@/assets/product/porduct1_1.jpg"),
          img3: require("@/assets/product/porduct1_3.jpg"),
          img4: require("@/assets/product/porduct1_4.jpg"),
          }
          this.dialogVisible = true
        break;
      case 2:
        this.productInfo = {
          text: '一心诚与极上北京安宫牛黄丸于2023年达成代理合作。极上北京安宫牛黄丸是中国北京传统古方，以水牛角、天然牛黄、天然麝香、珍珠等多种名贵药材制成，保护心脑血管健康。以最珍贵呵护健康。',
          img1: require("@/assets/product/porduct1_5.jpg"),
          img2: require("@/assets/product/porduct1_1.jpg"),
          img3: require("@/assets/product/porduct1_3.jpg"),
          img4: require("@/assets/product/porduct1_4.jpg"),
        }
        break;
      case 3:
        this.productInfo = {
          text: '一心诚与极上北京安宫牛黄丸于2023年达成代理合作。极上北京安宫牛黄丸是中国北京传统古方，以水牛角、天然牛黄、天然麝香、珍珠等多种名贵药材制成，保护心脑血管健康。以最珍贵呵护健康。',
          img1: require("@/assets/product/porduct1_5.jpg"),
          img2: require("@/assets/product/porduct1_1.jpg"),
          img3: require("@/assets/product/porduct1_3.jpg"),
          img4: require("@/assets/product/porduct1_4.jpg"),
        }
        break;
      case 4:
        this.productInfo = {
          text: '一心诚与极上北京安宫牛黄丸于2023年达成代理合作。极上北京安宫牛黄丸是中国北京传统古方，以水牛角、天然牛黄、天然麝香、珍珠等多种名贵药材制成，保护心脑血管健康。以最珍贵呵护健康。',
          img1: require("@/assets/product/porduct1_5.jpg"),
          img2: require("@/assets/product/porduct1_1.jpg"),
          img3: require("@/assets/product/porduct1_3.jpg"),
          img4: require("@/assets/product/porduct1_4.jpg"),
        }
        break;
      case 5:
        this.productInfo = {
          text: '一心诚与极上北京安宫牛黄丸于2023年达成代理合作。极上北京安宫牛黄丸是中国北京传统古方，以水牛角、天然牛黄、天然麝香、珍珠等多种名贵药材制成，保护心脑血管健康。以最珍贵呵护健康。',
          img1: require("@/assets/product/porduct1_5.jpg"),
          img2: require("@/assets/product/porduct1_1.jpg"),
          img3: require("@/assets/product/porduct1_3.jpg"),
          img4: require("@/assets/product/porduct1_4.jpg"),
        }
        break;
       case 6:
        this.productInfo = {
          text: '一心诚与极上北京安宫牛黄丸于2023年达成代理合作。极上北京安宫牛黄丸是中国北京传统古方，以水牛角、天然牛黄、天然麝香、珍珠等多种名贵药材制成，保护心脑血管健康。以最珍贵呵护健康。',
          img1: require("@/assets/product/porduct1_5.jpg"),
          img2: require("@/assets/product/porduct1_1.jpg"),
          img3: require("@/assets/product/porduct1_3.jpg"),
          img4: require("@/assets/product/porduct1_4.jpg"),
        }
        break;
    }

   
  }

   gotoUrl() {
    window.location.href = 'https://beian.miit.gov.cn'
   }

   goto(data: string){
    window.location.hash = data
   }

   mounted() {
    this.show = true
   }

}

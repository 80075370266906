import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d40363f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail-contain" }
const _hoisted_2 = { class: "text-style" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.productInfo.text), 1),
    (_ctx.productInfo.img1)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.productInfo.img1
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.productInfo.img2)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.productInfo.img2
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.productInfo.img3)
      ? (_openBlock(), _createElementBlock("img", {
          key: 2,
          src: _ctx.productInfo.img3
        }, null, 8, _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.productInfo.img4)
      ? (_openBlock(), _createElementBlock("img", {
          key: 3,
          src: _ctx.productInfo.img4
        }, null, 8, _hoisted_6))
      : _createCommentVNode("", true),
    (_ctx.productInfo.img5)
      ? (_openBlock(), _createElementBlock("img", {
          key: 4,
          src: _ctx.productInfo.img5
        }, null, 8, _hoisted_7))
      : _createCommentVNode("", true)
  ]))
}

import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    productInfo: Object
  }
})
export default class HelloWorld extends Vue {
  msg!: string
}

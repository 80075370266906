<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

html, body {
    position: relative;
    height: 100%;
    scroll-behavior: smooth;
    margin: 0 !important;
    padding: 0 !important;
}



::-webkit-scrollbar
{
  width: 6px;     /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;

}

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.1);
    border-radius: 20px;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb
{
    -webkit-box-shadow:  0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
    border-radius: 20px;
}
/*定义最上方和最下方的按钮*/

.fullLoading {
  width: 100%;
  height: 100%;
}

</style>
